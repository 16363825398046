import * as React from "react"
import { Color } from "../../util/Color"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LeftTitle from "../../components/feature/Home/LeftTitle"

const description = ""

const IndexPage = () => {
  const [view, setView] = React.useState("PC")

  React.useEffect(() => {
    const w = window.innerWidth
    setView(
      (() => {
        if (1440 < w) return "PC"
        else if (960 < w) return "TABLET"
        else return "MOBILE"
      })()
    )
  })

  return (
    <Layout>
      <Seo title="Service" description={description} />
      <Contents view={view}>
        <LeftTitle en="Message" ja="応募者の皆さまへ" id="PLM" view={view} />
        <Recruitment view={view} />
      </Contents>
      <Contents view={view}>
        <LeftTitle
          en="BCI"
          ja="ブロックチェーンイノベーション事業部"
          id="BCI"
          view={view}
        />
        <BCI view={view} />
      </Contents>
      <Contents view={view}>
        <LeftTitle en="PLM" ja="PLM事業部" id="PLM" view={view} />
        <PLM view={view} />
      </Contents>
      <Contents view={view}>
        <LeftTitle en="CareerPath" ja="キャリアパス" id="Career" view={view} />
        <Career view={view} />
      </Contents>
    </Layout>
  )
}

const Recruitment = ({ view }) => {
  return (
    <Wrapper reverse={true}>
      <Colomn>
        <Text view={view}>
          OpeningLineでは、新しい技術とアイディアに挑戦し続ける意欲的な仲間を求めています。
        </Text>
        <Text view={view}>
          私たちのチームでは、「学習する姿勢」を大切にしています。
        </Text>
        <Text view={view}>
          常に新しいことを学び、練習を重ねることで、個々のスキルはもちろん、チーム全体の成長にも繋がります。
        </Text>
        <Text view={view}>
          私たちは、皆さんの努力と成果を正当に評価し、それに応じた報酬を提供します。
        </Text>
        <Text view={view}>代表取締役 佐々木亮一</Text>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../../images/Sasakisama1.JPG" alt="佐々木亮一" />
      </ImageWrapper>
    </Wrapper>
  )
}

const BCI = ({ view }) => {
  return (
    <Wrapper reverse={false}>
      <Colomn>
        <Text view={view}>ブロックチェーンイノベーション事業部では</Text>
        <Text view={view}>
          新しいことを世の中に根付かせるために挑戦し続ける意欲的な仲間を求めています。
        </Text>
        <Text view={view}>
          日々挑戦の連続ですが、皆さんの挑戦に対して正当な評価を行い、成果に見合った報酬を提供します。
        </Text>
        <Text view={view}>
          是非とも応募者の皆さまの熱意あふれるご応募をお待ちしております。
        </Text>
        <Text view={view}>BCI事業部 部長 岡田和也</Text>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../../images/Okadasama2.JPG" alt="岡田和也" />
      </ImageWrapper>
    </Wrapper>
  )
}

const PLM = ({ view }) => {
  return (
    <Wrapper reverse={true}>
      <Colomn>
        <Text view={view}>
          PLM事業部では、製造業のライフサイクルソフトウェアの導入支援を行っています。
        </Text>
        <Text view={view}>
          求める人物像としては、適切な知識とお客様とのコミュニケーション能力が求められます。
        </Text>
        <Text view={view}>
          新しいことをキャッチアップしていき、お客様の課題解決に貢献していくことが求められます。
        </Text>
        <Text view={view}>
          そのため、学習する量も多大にあり、その分成長と報酬も大きいです。
        </Text>
        <Text view={view}>代表取締役 佐々木亮一</Text>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../../images/Sasakisama2.JPG" alt="佐々木亮一" />
      </ImageWrapper>
    </Wrapper>
  )
}

const Career = ({ view }) => {
  return (
    <ImageCareerWrapper view={view}>
      <StaticImage src="../../images/career.png" alt="キャリアパス" />
    </ImageCareerWrapper>
  )
}

export default IndexPage

const ImageCareerWrapper = styled("div")(({ view }) => ({
  width: view === "PC" ? "1280px" : view === "TABLET" ? "480px" : "100vw",
  height: view === "PC" ? "600px" : view === "TABLET" ? "480px" : "",
  marginBottom: view === "MOBILE" ? "24px" : "0px",
}))

const ImageWrapper = styled("div")(({ view }) => ({
  width: view === "PC" ? "560px" : view === "TABLET" ? "480px" : "100vw",
  height: view === "PC" ? "240px" : view === "TABLET" ? "480px" : "",
  marginBottom: view === "MOBILE" ? "24px" : "0px",
}))

const Colomn = styled("div")(({ view }) => ({
  display: "flex",
  flexDirection: "column",
  width: "720px",
  height: view === "PC" ? "560px" : view === "TABLET" ? "480px" : "auto", // 高さを追加
}))

const Contents = styled("div")(({ view }) => ({
  margin: view === "MOBILE" ? "0px" : "0px 320px",
  width: view === "PC" ? "1280px" : view === "TABLET" ? "900px" : "100%",
}))

const Wrapper = styled("div")(({ reverse }) => ({
  width: "100%",
  display: "flex",
  flexDirection: reverse ? "row-reverse" : "row",
  alignItems: "stretch", // ここを変更
  justifyContent: "space-between",
  marginTop: "40px",
  marginBottom: "160px",
  gap: "80px",
}))

const Text = styled("div")(({ view }) => ({
  color: Color.black,
  fontFamily: "Noto Sans JP",
  fontSize: view === "PC" ? "20px" : "14px",
  lineHeight: "2",
}))
